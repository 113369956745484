import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../../components/seo"
import AnimationWrapper from "../../components/animationWrapper"
import ContactSection from "../../components/contactSection"
import Container from "../../components/container"
import DownloadSection from "../../components/downloadSection"
import Header from "../../components/header"
import InnerSection from "../../components/innerSection"
import LightboxImageSection from "../../components/lightboxImageSection"
import TextSection from "../../components/textSection"
import Layout from "../../components/layout"

import Tassu1 from '../../images/szalunki-fundamentowe/lammi-tassu.png'
import Tassu2 from '../../images/szalunki-fundamentowe/lammi-tassu-2.png'
import TassuLogo from '../../../static/img/lammi-tassu-logo.jpg'

import "./produkty.css"

const SzalunkiPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Zobacz!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <Container>
        <TextSection className="tassu-title description-medium" data={data.page.frontmatter.text_sections[0]} />
      </Container>

      <InnerSection className="tassu-inner-logo">
        <div className="description-container">
            <TextSection className="description-medium justify-right" data={data.page.frontmatter.text_sections[1]} />
        </div>
        <div className="image-container">
          <div className="content-container">
            <img className="tassu-logo" src={TassuLogo} alt="LAMMI TASSU" />
          </div>
        </div>
      </InnerSection>

      <InnerSection>
        <div className="image-container">
          <div className="content-container">
            <img src={Tassu1} alt="LAMMI TASSU" />
          </div>
        </div>
        <div className="description-container">
            <TextSection className="description-medium justify-left" data={data.page.frontmatter.text_sections[2]} />
            <TextSection className="description-medium justify-left" data={data.page.frontmatter.text_sections[3]} />
            <TextSection className="description-medium justify-left" data={data.page.frontmatter.text_sections[4]} />
        </div>
      </InnerSection>

      <InnerSection className="tassu-inner-2">
        <div className="description-container">
            <TextSection className="description-medium justify-right justify-right" data={data.page.frontmatter.text_sections[5]} />
            <TextSection className="description-medium justify-right justify-right" data={data.page.frontmatter.text_sections[6]} />
        </div>
        <div className="image-container">
          <div className="content-container">
            <img src={Tassu2} alt="LAMMI TASSU" />
          </div>
        </div>
      </InnerSection>

      <LightboxImageSection image={data.wykres} />

      <DownloadSection data={data.downloadTassu} className="tassu-download" location="Materiały do pobrania" />

      <LightboxImageSection image={data.rzut1} />

      <LightboxImageSection image={data.rzut2} />

      {data.page.frontmatter.text_section_bottom.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_bottom} />
          </AnimationWrapper>
        </Container>
      }

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "szalunki-fundamentowe" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_sections{
          title
          title_visible
          icon{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          text
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    downloadTassu: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(downloads)/"}},
        frontmatter: {category: {eq: "tassu"}}
        }
    ) {
      nodes {
        frontmatter {
          name
          file {
            publicURL
          }
        }
        id
      }
    }
    wykres: file(base: {eq: "tassu-wykres.jpg"}, relativeDirectory: {eq: "szalunki-fundamentowe"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    rzut1: file(base: {eq: "rzut-1.png"}, relativeDirectory: {eq: "szalunki-fundamentowe"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    rzut2: file(base: {eq: "rzut-2.png"}, relativeDirectory: {eq: "szalunki-fundamentowe"}) {
      id
      publicURL
      childImageSharp {
        gatsbyImageData
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default SzalunkiPage
